/* eslint-disable react/forbid-prop-types */
import React from 'react'
import styled from 'styled-components'
import useWebsiteSettings from '../../utils/build-helpers/useWebsiteSettings'
import { above } from '../../styles'

const SocialMediaIcons = ({ locale }) => {
  const {
    socialMediaLink1,
    socialMediaLink1Icon,
    socialMediaLink2,
    socialMediaLink2Icon,
    socialMediaLink3,
    socialMediaLink3Icon,
    socialMediaLink4,
    socialMediaLink4Icon,
  } = useWebsiteSettings(locale)

  const socialMediaLinks = [
    { link: socialMediaLink1, icon: socialMediaLink1Icon },
    { link: socialMediaLink2, icon: socialMediaLink2Icon },
    { link: socialMediaLink3, icon: socialMediaLink3Icon },
    { link: socialMediaLink4, icon: socialMediaLink4Icon },
  ]

  return (
    <List>
      {socialMediaLinks.map(({ link, icon }) => {
        if (!link || !icon) {
          return null
        }

        return (
          <ListItem key={link.id}>
            <Link href={link.externalUrl} target="_blank" rel="noreferrer noopener">
              <Icon src={icon.file.url} alt={link.title} />
            </Link>
          </ListItem>
        )
      })}
    </List>
  )
}

const List = styled.ul`
  ${above.tablet`
    text-align: right;
  `}

  ${above.tabletLarge`
    text-align: inherit;
  `}

  ${above.desktopLarge`
    text-align: right;
  `}
`

const ListItem = styled.li`
  display: inline-block;
  margin: 0 25px 0 0;

  &:last-of-type {
    margin-right: 0;
  }
`

const Link = styled.a`
  &:focus {
    padding: 10px 0;
  }
`

const Icon = styled.img`
  height: 26px;
`

export default SocialMediaIcons
