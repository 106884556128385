import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import sanitize from 'sanitize-filename'

export const ContentfulLink = ({ linkData, allPages, customTitle }) => {
  const { title, externalUrl, content, asset, assetSlug } = linkData

  if (content) {
    const link = `/${allPages[content.id]}`.replace('//', '/')
    return <GatsbyLink to={link}>{customTitle || title}</GatsbyLink>
  }

  if (asset) {
    const slug = assetSlug ? `${sanitize(assetSlug).replace(/ /g, '-')}.pdf` : asset.file.fileName
    const prefix = [undefined, '/'].includes(process.env.GATSBY_PATH_PREFIX)
      ? ''
      : `/${process.env.GATSBY_PATH_PREFIX}`
    return (
      <a href={`${prefix}/docs/${slug}`} target="_blank" rel="noreferrer">
        {customTitle || title}
      </a>
    )
  }

  if (externalUrl) {
    const link = externalUrl
    return (
      <a href={link} target="_blank" rel="noreferrer">
        {title}
      </a>
    )
  }

  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  return <a href="#">{title}</a>
}
