import React from 'react'

import { BlockBreakoutFactBenefits, BlockPromo, BlockRichText } from '../components'

const contentBlockList = {
  ContentfulBlockBreakoutFactBenefits: props => <BlockBreakoutFactBenefits data={props} />,
  ContentfulBlockPromo: props => <BlockPromo data={props} />,
  ContentfulBlockRichText: props => <BlockRichText data={props} />,
}

export const renderContentBlocks = ({ component = {} }, allPages) => {
  if (!component.__typename || !component.id || !contentBlockList[component.__typename]) {
    return null
  }

  const Component = contentBlockList[component.__typename]
  return <Component key={component.id} allPages={allPages} {...component} />
}
