import React from 'react'
import styled from 'styled-components'

import GlobalStyles from '../../styles/global'
import { above } from '../../styles'

import { Header, Footer, SecondaryNavigation } from '../index'
import '../../styles/css/fontface.css'

const Layout = ({
  children,
  hero,
  secondaryNavigation,
  title,
  locale,
  intl,
  allPages,
  location,
}) => {
  return (
    <>
      {/* @todo: move globals to browser / ssr */}
      <GlobalStyles />
      <Header
        hero={hero}
        title={title}
        locale={locale}
        intl={intl}
        allPages={allPages}
        location={location}
      />
      {secondaryNavigation && (
        <SecondaryNavigation
          secondaryNavigation={secondaryNavigation}
          allPages={allPages}
          location={location}
        />
      )}
      <Main>{children}</Main>
      <Footer locale={locale} allPages={allPages} />
    </>
  )
}

const Main = styled.main`
  margin: 50px auto;
  max-width: 1392px;
  min-height: 400px;

  ${above.tablet`
    margin: 92px auto;
  `}
`

export default Layout
