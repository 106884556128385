import React from 'react'
import styled from 'styled-components'
import RichText from '../../richText'
import { color, gothamMedium, above } from '../../../styles'

const BlockRichText = ({ data: { content, title, style } }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Content background={style}>
        <RichText data={content} />
      </Content>
    </Container>
  )
}

const Container = styled.article`
    margin: 0px 2.5%;
  ${above.tablet`
    margin: 0px 6%;
  `}
  
  ${above.tabletLarge`
    margin: 0px 9%;
  `}
  
  ${above.desktopLarge`
    margin: 0px 4%;
    max-width: 1274px;
  `}
`

const Title = styled.h2`
  color: ${color.costaRed};
`

const Content = styled.div`
  background: ${p => p.background};
  padding: 0;
  overflow: hidden;
  font-size: 16px;
  line-height: 1.5;
  padding: 56px 2.5%;

  

  ${above.tablet`
    font-size: 18px;
    padding: 56px 5%;
  `}

   ${above.tabletLarge`
    padding: 56px 12% 56px 8%;
  `}

  ${above.desktop`
    padding: 56px 236px 56px 118px;
  `}

  p {
    margin: 16px 0 32px;
    padding: 0;

    ${above.tablet`
      margin: 18px 0 32px;
    `}
  }

  strong {
    ${gothamMedium}
  }

  a {
    ${gothamMedium}
    color: ${color.costaRed};

    &:hover {
      color: ${color.brightRed}
    }
  }

  ul, ol {
    p {
      padding: 0;
      margin: 0;
    }
  }
  ul {
    padding-left: 40px;
   li {
    list-style-type: inherit;
    }
  }

  ol {
    padding-left: 40px;
   li {
    list-style-type: inherit;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 40px;
    color: ${color.costaRed};

    ${above.tablet`
      margin: 0 0 29px;
    `}
  }

  .inline-right,
  .inline-left
   {
    display: block;
    width: 100%;
    padding-bottom: 27px;

    ${above.tabletLarge`
      width: 48%;
      box-sizing: content-box;
    `}

    ${above.desktop`
      width: 389px;
    `}
  }

  .inline-right {
    ${above.tablet`
      float: right;
    `}
    ${above.tabletLarge`
      margin-right: -10%;
      padding: 0 0 0 40px;
    `}
    ${above.desktop`
      margin-right: -204px;
      padding: 0 0 40px 40px;
    `}
  }

  .inline-left {
    ${above.tablet`
      float: left;
      padding: 0 40px 40px 0;
    `}
  }

`

export default BlockRichText
