/* eslint-disable no-use-before-define */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import ReactPlayer from 'react-player'
import RichText from '../../richText'
import LinkCta from '../../linkCta'
import { color, above } from '../../../styles'

const Campaign = ({
  style,
  imageAlignment,
  campaignStyleBgColour,
  campaignStyleTextColour,
  content,
  image,
  primaryAction,
  primaryActionLabel,
  primaryActionStyle,
  secondaryAction,
  secondaryActionLabel,
  secondaryActionStyle,
  allPages,
}) => {
  const mediaType = image.file.contentType
  const textColour =
    campaignStyleBgColour && campaignStyleTextColour !== 'standard' ? color.white : color.darkGray
  return (
    <Block imageAlignment={imageAlignment}>
      <Content campaignStyleBgColour={campaignStyleBgColour} textColour={textColour}>
        <RichText data={content} />
        {primaryAction && (
          <LinkCta
            link={primaryAction}
            customTitle={primaryActionLabel || primaryAction.title}
            ctaStyle={primaryActionStyle || 'button'}
            alignment="center"
            allPages={allPages}
            css={{ 'margin-top': '18px' }}
            customColor={(campaignStyleTextColour !== 'standard' && campaignStyleBgColour) || null}
            customLinkColor={
              (campaignStyleTextColour !== 'standard' && campaignStyleBgColour) || null
            }
          />
        )}
        {secondaryAction && (
          <LinkCta
            link={secondaryAction}
            customTitle={secondaryActionLabel || secondaryAction.title}
            ctaStyle={secondaryActionStyle || 'link'}
            alignment="center"
            allPages={allPages}
            css={{ 'margin-top': '18px' }}
            customColor={(campaignStyleTextColour !== 'standard' && campaignStyleBgColour) || null}
            customLinkColor={
              (campaignStyleTextColour !== 'standard' && campaignStyleBgColour) || null
            }
          />
        )}
        {/* <RichText promoStyle={style}>{richTextRenderer(richText.json, nodeLocale)}</RichText> */}
      </Content>
      {mediaType.includes('image') && (
        <GatsbyImage
          fluid={image.fluid}
          alt={image.description}
          promoStyle={style}
          imageAlignment={imageAlignment}
        />
      )}
      {mediaType.includes('video') && (
        <VideoContainer>
          <ReactPlayer
            url={image.file.url}
            playsinline
            playing
            loop
            muted
            wrapper={Video}
            height="100%"
            width="100%"
          />
        </VideoContainer>
      )}
    </Block>
  )
}

const Block = styled.article`
  margin: 80px auto;
  padding: 0;
  ul {
    margin: 0px;
    padding: 0px;
  }

  ${above.tablet`
    max-width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: ${p => p.imageAlignment}
  `}

  ${above.desktopLarge`
    max-width: 1392px;
  `}
`

const Content = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  background-color: ${p => p.campaignStyleBgColour};
  color: ${p => p.textColour};
  list-style: none;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 48px 10%;
  min-height: 300px;
  text-align: center;

  ${above.mobile`
    padding: 96px 10%;
  `}

  ${above.tablet`
  width: 50%;
  padding: 80px 5%;
  min-height: 512px;
  `}

  ${above.desktop`
  padding: 80px 6.5%;
  `}

  h1,h2,h3,h4,h5,h6 {
    color: ${p => (p.textColour === color.white ? color.white : color.costaRed)};
    margin: 0 0 20px 0;
    width: 100%;
  }

  p {
    margin-top: 0;
    color: ${p => p.textColour};
    width: 100%;

    ${above.desktop`
    padding: 0px 30px;
    `};
  }

  a {
    margin-bottom: 0;
  }
`

// const SeasonalSVG = styled.img`
//   left: ${({ imageAlignment }) => (imageAlignment === 'initial' ? '10%' : 'auto')};
//   right: ${({ imageAlignment }) => (imageAlignment === 'row-reverse' ? '10%' : 'auto')};
//   z-index: -1;
//   position: absolute;
//   top: -64px;
//   max-width: 100px;
//   max-height: 100px;
// `

// const RichText = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   margin: 0 auto 16px;
//   text-align: center;

//   ${above.tablet`
//     margin: 0 auto 18px;
//   `}
// `

const GatsbyImage = styled(Img)`
  width: auto;
  margin: 0;
  min-height: 300px;

  img {
    margin-bottom: 0;
  }

  ${above.tablet`
    width: 50%;
    height: auto;
    min-height: 512px;
  `}
`

const VideoContainer = styled.div`
  display: flex;
  min-height: 73px;
  position: relative;

  ${above.tablet`
    width: 50%;
    height: auto;
    min-height: 512px;
  `};
`

const Video = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  video {
    min-width: 100%;
    min-height: 100%;
    width: 100% !important;
    height: auto !important;

    ${above.tablet`
      position: absolute;
      width: auto !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `};
  }
`

Campaign.defaultProps = {
  style: '',
  imageAlignment: '',
  campaignStyleBgColour: '',
  campaignStyleTextColour: '',
  // richText: {},
  image: {},
  // linkCTA: [],
}

Campaign.propTypes = {
  style: PropTypes.string,
  imageAlignment: PropTypes.string,
  campaignStyleBgColour: PropTypes.string,
  campaignStyleTextColour: PropTypes.string,
  // richText: PropTypes.object,
  image: PropTypes.object,
  // linkCTA: PropTypes.array,
}

export default Campaign
