import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { renderContentBlocks } from '../utils/content-blocks'
import AnalyticsDataLayer from '../utils/analyticsDataLayer'
import Layout from '../components/layout'

export const PageQuery = graphql`
  query($id: String!) {
    contentfulPage(id: { eq: $id }) {
      id
      contentful_id
      locale: node_locale
      title
      description
      slug
      hero {
        ...FragmentHero
      }
      secondaryNavigation {
        ...FragmentNavigationGroup
      }
      contentBlocks {
        ... on Node {
          ... on ContentfulBlockBreakoutFactBenefits {
            ...FragmentBlockBreakoutFactBenefits
          }
          ... on ContentfulBlockPromo {
            ...FragmentBlockPromo
          }
          ... on ContentfulBlockRichText {
            ...FragmentBlockRichText
          }
        }
      }
    }
  }
`

const Page = ({ data, pageContext, location }) => {
  const page = data.contentfulPage
  const { allPages, intl, locale } = pageContext
  const contentBlocks = page.contentBlocks || []

  return (
    <div>
      <Helmet>
        <title>{page.title}</title>
        <meta name="description" content={page.description} />
        <html lang={page.locale} />
      </Helmet>
      {process.env.GATSBY_BUDDY_PIPELINE !== 'uk' && (
        <AnalyticsDataLayer title={page.title} locale={locale} location={location} />
      )}
      <Layout
        hero={page.hero}
        secondaryNavigation={page.secondaryNavigation}
        title={page.title}
        locale={page.locale}
        intl={intl}
        allPages={allPages}
        location={location}
      >
        {contentBlocks.map(component =>
          renderContentBlocks(
            {
              component,
            },
            allPages
          )
        )}
      </Layout>
    </div>
  )
}

export default Page
