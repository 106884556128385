import React, { useState } from 'react'
import styled, { css } from 'styled-components'
// import BackgroundImage from 'gatsby-background-image'
import { Link } from 'gatsby'

import Hamburger from './hamburger/hamburger'
import Logo from './logo/logo'
import MainNav from './mainNav/mainNav'
// import IconsBar from './iconsBar/iconsBar'
// import InternationalIconsBar from '../International/IconsBar/iconsBar'
import waffle from '../../assets/backgrounds/waffle2.svg'
import useWebsiteSettings from '../../utils/build-helpers/useWebsiteSettings'
import { color, above } from '../../styles'
// import Hero from './hero/hero'
import Hero from './hero/hero'
// import LanguageSelector from '../languageSelector/languageSelector'
// import { localeFormatter } from '../../helpers/localeFormatter/localeFormatter'

// TODO: remove hardcoded
// const defaultLocale = process.env.GATSBY_DEFAULT_LOCALE
// const country = process.env.GATSBY_BUDDY_PIPELINE
const headerIcons = process.env.GATSBY_HEADER_ICONS || 'false'

const Header = ({ hero, location, locale, allPages }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { mobileMenuAriaLabel, logoAriaLabel, mainNavigation } = useWebsiteSettings(locale)

  return (
    <>
      <Wrapper data-cy="header-desktop" withHero={!!hero} className={isMenuOpen && 'menuOpen'}>
        <MaxWidth>
          {mainNavigation?.links && (
            <MobileMenu
              data-cy="header-mobile"
              aria-label={mobileMenuAriaLabel}
              role="button"
              tabIndex="0"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              onKeyPress={() => setIsMenuOpen(!isMenuOpen)}
            >
              <Hamburger open={isMenuOpen} />
            </MobileMenu>
          )}
          <HomeLink
            aria-label={logoAriaLabel}
            to="/"
            onClick={() => setIsMenuOpen(false)}
            onKeyPress={() => setIsMenuOpen(false)}
            mainNavigation={mainNavigation}
          >
            <Logo alt={logoAriaLabel} locale={locale} />
          </HomeLink>
          <MainNav
            location={location}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            locale={locale}
            allPages={allPages}
          />
          {/* {country !== 'uk' && (
            <LanguageSelectorContainer className={isMenuOpen && 'menuOpen'}>
              <LanguageSelector location={location} />
            </LanguageSelectorContainer>
          )} */}
          {/* {headerIcons === 'true' && <IconsBar locale={locale} />} */}
        </MaxWidth>
      </Wrapper>
      {hero && <Hero content={hero} />}
    </>
  )
}

// const LanguageSelectorContainer = styled.div`
//   display: none;
//   position: relative;
//   right: 0;
//   top: -8px;

//   ${above.tabletLarge`
//     display: block;
//   `}

//   ${above.desktop`
//     top: 0;
//   `}
// `

const openNav = css`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: ${color.costaRed} !important;
  background-image: url(${waffle}) !important;
  align-items: inherit;
  transform: translateX(0%);
  transition: transform 250ms ease-in-out;

  nav {
    display: block;
    position: fixed;
    left: 0;
    width: 100%;
    top: 74px;
    height: 100%;
    transform: translateX(0%);
    transition: transform 250ms ease-in-out;

    li {
      display: block;
      height: 38px;
      line-height: 38px;
      font-size: 25px;
      padding: 4px 4px;
      width: 247px;
      margin-left: 36px;
      text-align: left;
      box-sizing: content-box;
    }
  }
`

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 12px;
  height: 80px;
  z-index: 1;
  width: 100%;

  ${p =>
    (p.withHero &&
      css`
        position: absolute;
      `) ||
    css`
      background: ${color.costaRed};
    `}

  ${above.desktop`
    padding: 34px 0;
    height: 148px;
  `}

  &.menuOpen {
    ${openNav};
  }
`

const MaxWidth = styled.div`
  max-width: 1392px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  ${() =>
    headerIcons !== 'true' &&
    css`
      margin: 12px 0;

      ${above.desktop`
        margin: 0 auto;
      `}
    `};
`

const HomeLink = styled(Link)`
  color: ${color.white};
  text-decoration: none;
  ${p =>
    headerIcons !== 'true' &&
    css`
      margin: 0 auto;
      padding-right: ${p.mainNavigation ? `92px` : `0`};

      ${above.tabletLarge`
        padding-right: 0;
      `}
    `}

  ${above.tabletLarge`
    margin: 3px 24px 0 40px;
  `}
`
const MobileMenu = styled.div`
  display: block;
  ${above.tabletLarge`
    display: none;
  `}
`

export default Header
