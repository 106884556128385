import React from 'react'
import styled from 'styled-components'

import Icon from './icon'
import { color, above, manus } from '../../../styles'

const BreakoutFactWrapper = styled.article`
  text-align: center;
  width: 79%;
  margin: 72px auto;

  ${above.tablet`
    width: 72%;
    margin: 80px auto;
  `}

  ${above.desktop`
    width: 100%;
    max-width: 803px;
  `}
`

const Text = styled.p`
  margin: 0;
  ${manus}
  font-size: 30px;
  line-height: 1.1;
  color: ${props => props.color};

  ${above.tablet`
    font-size: 50px;
  `}
`

const BlockBreakoutFactBenefits = ({ data: { icon, colouredText, textColour } }) => {
  return (
    <BreakoutFactWrapper>
      <Icon src={icon} alt="" />
      <Text color={textColour || color.costaOrange}>{colouredText}</Text>
    </BreakoutFactWrapper>
  )
}

export default BlockBreakoutFactBenefits
