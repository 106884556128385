/* eslint-disable react/forbid-prop-types */
import React from 'react'
import Teaser from './teaser'
import Standard from './standard'
import Campaign from './campaign'

/**
 * @description This is a wrapper for the Promo Component. It renders
 * the different Promo variations: Standard, Campaign and Teaser.
 */
const BlockPromo = ({ data }) => {
  const {
    style,
    standardStyleBgColour,
    campaignStyleBgColour,
    campaignStyleTextColour,
    image,
    imageAlignment = 'right',
    content,
    primaryAction,
    primaryActionLabel,
    primaryActionStyle,
    secondaryAction,
    secondaryActionLabel,
    secondaryActionStyle,
    allPages,
  } = data

  const align = imageAlignment === 'left' ? 'row-reverse' : 'initial'

  switch (style) {
    case 'teaser':
      return (
        <Teaser
          image={image}
          imageAlignment={align}
          content={content}
          primaryAction={primaryAction}
          primaryActionLabel={primaryActionLabel}
          primaryActionStyle={primaryActionStyle}
          secondaryAction={secondaryAction}
          secondaryActionLabel={secondaryActionLabel}
          secondaryActionStyle={secondaryActionStyle}
          allPages={allPages}
        />
      )
    case 'campaign':
      return (
        <Campaign
          imageAlignment={align}
          campaignStyleBgColour={campaignStyleBgColour}
          style={style}
          content={content}
          campaignStyleTextColour={campaignStyleTextColour}
          image={image}
          primaryAction={primaryAction}
          primaryActionLabel={primaryActionLabel}
          primaryActionStyle={primaryActionStyle}
          secondaryAction={secondaryAction}
          secondaryActionLabel={secondaryActionLabel}
          secondaryActionStyle={secondaryActionStyle}
          allPages={allPages}
        />
      )
    case 'standard':
      return (
        <Standard
          style={style}
          standardStyleBgColour={standardStyleBgColour}
          image={image}
          imageAlignment={align}
          content={content}
          primaryAction={primaryAction}
          primaryActionLabel={primaryActionLabel}
          primaryActionStyle={primaryActionStyle}
          secondaryAction={secondaryAction}
          secondaryActionLabel={secondaryActionLabel}
          secondaryActionStyle={secondaryActionStyle}
          allPages={allPages}
        />
      )
    default:
      return null
  }
}

export default BlockPromo
