import React from 'react'
import styled from 'styled-components'
import useWebsiteSettings from '../../utils/build-helpers/useWebsiteSettings'
import useMicrocopy from '../../utils/build-helpers/useMicrocopy'
import { ContentfulLink } from '../../utils/link'
import waffle from '../../images/assets/waffle.svg'
import { getMicrocopy } from '../../utils/microcopy'
import { color, above, gothamBook, gothamMedium, gothamBold } from '../../styles'

import SocialMediaLinks from '../socialMediaIcons/socialMediaIcons'

const StyledFooter = ({ locale, allPages }) => {
  const data = useMicrocopy(locale)
  const { footerLinkGroups, footerLegalLinks, enableSocialMediaIcons } = useWebsiteSettings(locale)

  return (
    <Footer>
      <Footer.Image>
        <Footer.Main>
          {footerLinkGroups?.map(group => (
            <Main.Container
              key={group.title}
              className={`
                ${footerLinkGroups.length <= 4 ? ' fourCol ' : ''} 
                ${footerLinkGroups.length === 5 ? ' fiveCol ' : ''} 
                ${footerLinkGroups.length === 6 ? ' sixCol ' : ''}
              `}
            >
              <Main.Heading>{group.title}</Main.Heading>
              <Main.List>
                {group?.links?.map(link => (
                  <li key={link.title}>
                    <ContentfulLink linkData={link} allPages={allPages} />
                  </li>
                ))}
              </Main.List>
            </Main.Container>
          ))}
        </Footer.Main>
      </Footer.Image>
      <Footer.Bottom>
        <Bottom.Container>
          <Bottom.List>
            {footerLegalLinks.links.map(link => (
              <li key={link.title}>
                <ContentfulLink linkData={link} allPages={allPages} />
              </li>
            ))}
          </Bottom.List>
          <Bottom.Rights>
            {getMicrocopy({ key: 'footer.copyright', data, tokens: { year: '2021' } })}
          </Bottom.Rights>
          {enableSocialMediaIcons && (
            <Bottom.Icons>
              <SocialMediaLinks locale={locale} />
            </Bottom.Icons>
          )}
        </Bottom.Container>
      </Footer.Bottom>
    </Footer>
  )
}

const Footer = styled.footer`
  background: ${color.brightRed};
  color: ${color.white};
  ${gothamBook}

  line-height: 1.5;
`

Footer.Image = styled.div`
  position: relative;
  ${above.tablet`
    background-color: ${color.brightRed};
    background-image: url(${waffle});
  `}
`

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 40px 10.5%;
  max-width: 1274px;
  margin: auto;
  position: relative;
  z-index: 1;

  ${above.tablet`
    padding: 64px 5%;
    flex-direction: row;
    flex-wrap: wrap;
  `}

  ${above.tabletLarge`
    padding: 64px 4%;
  `}

  ${above.desktop`
    padding: 64px 83px;
  `}

  ${above.desktopLarge`
    max-width: 1558px;
  `}
`

Main.Container = styled.div`
  margin: 0 0 44px;

  :last-child {
    margin: 0;
  }

  ${above.tablet`
    flex: 0 0 20%;
    margin: 0 3% 0 0;

    :nth-of-type(n + 5) {
      margin: 0 3% 0 0;
    }

    :nth-of-type(6) {
      margin-right: 0;
    }
  `}

  ${above.tabletLarge`
    &.fourCol {
      flex: 0 0 19%;
      margin-right: 5.5%;
    }

    &.fiveCol {
      flex: 0 0 16.5%;
      margin-right: 2%;
    }

    &.sixCol {
      flex: 0 0 14%;
      margin-right: 2.5%;

      ul li a {
        font-size: 16px;
        ${gothamMedium}
        line-height: 24px;
      }
    }
  `}
`

Main.Heading = styled.h6`
  ${gothamBold}

  margin: 0 0 24px;
  font-size: 16px;
  line-height: 24px;
  ${above.tablet`
    margin: 0 0 40px;
    font-size: 18px;
    line-height: 28px;
  `}
`

Main.List = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;

  li {
    margin-bottom: 12px;

    a {
      padding: 0;
      ${gothamMedium}

      color: ${color.white};
      text-decoration: none;

      ${above.tablet`
        font-size: 18px;
      `}

      :hover {
        color: ${color.lightGray};
      }
    }
  }
  li:last-child {
    margin-bottom: 0;
  }
`

const Bottom = styled.div`
  background: ${color.costaRed};
  padding: 32px 10.5%;

  ${above.mobile`
    padding: 64px 10.5%;
  `}

  ${above.tablet`
    padding: 32px 5%;
  `}

  ${above.tabletLarge`
    padding: 32px 4%;
  `}

  ${above.desktopLarge`
    height: 80px;
    padding: 32px 80px;
  `}
`

Bottom.Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1392px;
  margin: 0 auto;
`

Bottom.List = styled.ul`
  margin: 0 0 28px 0;
  width: 100%;

  ${above.tablet`
    display: flex;
    flex-direction: row;
  `}

  ${above.tabletLarge`
    order: 2;
    width: auto;
    margin: 0 0 0 46px;
  `}

  li {
    ${above.tablet`
      margin-right: 48px;
    `}

    ${above.tabletLarge`
      margin-right: 30px;
    `}

    ${above.desktopLarge`
      margin-right: 48px;
    `}

    &:last-of-type {
      margin-right: 0;
    }
  }

  a {
    ${gothamMedium}
  }
`

Bottom.Rights = styled.p`
  width: 100%;
  margin: 0 0 28px 0;

  ${above.tablet`
    width: auto;
    margin-bottom: 0;
  `}

  ${above.tabletLarge`
    order: 1;
    width: auto;
  `}
`

Bottom.Icons = styled.div`
  width: 100%;

  ${above.tablet`
    width: auto;
    margin: 0 0 0 48px;
    flex-grow: 1;
  `}

  ${above.tabletLarge`
    order: 3;
    margin: 28px 0 0 0;
    width: 100%;
  `}

  ${above.desktopLarge`
    order: 3;
    margin: 0 0 0 48px;
    width: auto;
  `}
`

Footer.Main = Main
Footer.Bottom = Bottom

export default StyledFooter
