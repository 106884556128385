import { useStaticQuery, graphql } from 'gatsby'

const useMicrocopy = locale => {
  const { allContentfulMicrocopy } = useStaticQuery(graphql`
    query MicrocopyQuery {
      allContentfulMicrocopy {
        group(field: node_locale) {
          nodes {
            key
            value
            locale: node_locale
          }
          fieldValue
        }
      }
    }
  `)

  const microcopyPairs = {}

  allContentfulMicrocopy.group
    .find(nodes => nodes.fieldValue === locale)
    .nodes.forEach(microcopy => {
      microcopyPairs[microcopy.key] = microcopy.value
    })

  return microcopyPairs
}

export default useMicrocopy
