import React from 'react'
import styled from 'styled-components'
import useWebsiteSettings from '../../../utils/build-helpers/useWebsiteSettings'
import { ContentfulLink } from '../../../utils/link'
// import avatarIcon from '../../../assets/icons/avatar.svg'
// import LanguageSelector from '../../LanguageSelector/languageSelector'
import { above, brandonGrotesqueBold } from '../../../styles'
// import { showCorrectAccountOptions } from '../../../helpers/loginHelper'

export default ({ setIsMenuOpen, locale, allPages, location }) => {
  const { mainNavigation } = useWebsiteSettings(locale)

  // const checkActiveState = (link, path) => {
  //   const isJapanSite = process.env.GATSBY_BUDDY_PIPELINE === 'japan'

  //   if (link.htmlTarget || isJapanSite) {
  //     return false
  //   }

  //   if (link.internalLink && path.includes(encodeURIComponent(link.internalLink.slug))) {
  //     return true
  //   }

  //   if (
  //     link.internalLink?.parentAssembly &&
  //     path.includes(encodeURIComponent(link.internalLink?.parentAssembly?.slug))
  //   ) {
  //     return true
  //   }

  //   if (link.externalLink && path.includes(`/${link.externalLink.split('/')[3]}/`)) {
  //     return true
  //   }

  //   return false
  // }

  return (
    <MainNav>
      <ul>
        {mainNavigation?.links?.map(navItem => {
          return (
            <Links
              key={navItem.id}
              showActive={location?.pathname.includes(
                encodeURIComponent(allPages[navItem?.content?.id])
              )}
              onClick={() => setIsMenuOpen(false)}
              onKeyPress={() => setIsMenuOpen(false)}
            >
              <ContentfulLink linkData={navItem || {}} allPages={allPages} />
            </Links>
          )
        })}
        {/* {process.env.GATSBY_UK_SITE === 'false' && (
          <LanguageSelectorContainer>
            <LanguageSelector location={location} />
          </LanguageSelectorContainer>
        )} */}
      </ul>
      {/* {process.env.GATSBY_UK_SITE === 'true' && (
        <AccountManagement className={isMenuOpen && 'menuOpen'}>
          <AvatarIcon src={avatarIcon} />
          {typeof document !== `undefined` && showCorrectAccountOptions(document)}
        </AccountManagement>
      )} */}
    </MainNav>
  )
}

// const LanguageSelectorContainer = styled.div`
//   display: block;
//   background-color: #bd0d43;
//   margin: 80px 0;

//   li {
//     width: auto !important;
//     margin: 0 !important;
//   }

//   ${above.tabletLarge`
//     display: none;
//   `}
// `

const MainNav = styled.nav`
  display: none;
  ${above.tabletLarge`
  
    display: block;
  `}
  width: 100%;
  line-height: 1.15;
`

const Links = styled.li`
  display: inline-block;
  color: white;
  margin: 0 16px;
  ${brandonGrotesqueBold};
  font-size: 18px;

  &:last-child {
    margin-right: 0;
  }

  ${above.desktop`
    font-size: 24px;
  `}
  > a {
    border-bottom: ${props => props.showActive && '2px solid white'};
  }
`

// const AccountManagement = styled.div`
//   background-color: ${color.brightRed};
//   position: absolute;
//   bottom: 74px;
//   left: 0px;
//   width: 100%;
//   padding: 38px 0px;
//   background: ${color.redPink};
//   display: none;
//   &.menuOpen {
//     display: block;
//   }
//   ${above.tabletLarge`
//   display:none;
//   `}
//   > a {
//     font-size: 25px;
//     ${brandonGrotesqueBold};
//     color: ${color.white};
//   }
//   > a:nth-child(3n) {
//     display: block;
//     padding-left: 64px;
//     padding-top: 15px;
//   }
// `

// const AvatarIcon = styled.img`
//   display: inline-block;
//   ${above.tabletLarge`
//     display:none;
//   `}
//   overflow: hidden;
//   margin-left: 36px;
//   margin-right: 11px;
//   width: 17px;
//   height: 21px;
// `
