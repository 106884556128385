import React from 'react'
import styled from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { ContentfulLink } from '../../utils/link'
import { color, above, gothamBold } from '../../styles'

const LinkCta = ({
  alignment,
  ctaStyle,
  customTitle,
  link,
  customColor,
  customLinkColor,
  className,
  allPages,
}) => {
  return (
    <StyledLinkCta
      className={className}
      modifiers={[ctaStyle || 'button', alignment || 'left']}
      customColor={customColor}
      customLinkColor={customLinkColor}
    >
      {/* <ContentfulLink link={link} customTitle={customTitle} locale={link.node_locale} /> */}
      <ContentfulLink linkData={link} allPages={allPages} customTitle={customTitle} />
    </StyledLinkCta>
  )
}

const MODIFIER_CONFIG = {
  center: () => `text-align: center`,
  left: () => `text-align: left`,
  right: () => `text-align: right`,
  button: ({ customColor }) => `
    a {
      pointer-events: auto;
      background: ${customColor ? color.white : color.costaRed};
      color: ${customColor || color.white};
      padding: 15px 20px;
      border-radius: 32px;

      ${above.tablet`
        padding: 18px 27px;
      `}

      &:hover {
        background: ${customColor ? color.costaGrey : color.brightRed};
      }

      &:after {
        border-left: 6px solid ${customColor || color.white};
      }
    }
  `,

  link: ({ customLinkColor }) => `
    a {
      color: ${customLinkColor ? color.white : color.costaRed};
      padding: 15px 0;

      ${above.tablet`
        padding: 18px 0;
      `}

      &:after {
        border-left: 6px solid ${customLinkColor ? color.white : color.costaRed};
      }

      &:hover {
        color: ${color.brightRed};
        &:after {
        border-left: 6px solid ${color.brightRed};
      }
      }
    }
  `,
}

const StyledLinkCta = styled.div`
  a {
    ${gothamBold}
    display: inline-block;
    letter-spacing: 1px;
    cursor: pointer;
    margin-bottom: 16px;

    ${above.tablet`
      margin-bottom: 18px;
    `}

    &:after {
      content: '';
      display: inline-block;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      margin-left: 16px;

      ${above.tablet`
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
      `}
    }
  }
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`

export default LinkCta
