/* eslint-disable react/forbid-prop-types */
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import LinkCta from '../../linkCta'
import { above, color } from '../../../styles'
import RichText from '../../richText'

const Teaser = ({
  imageAlignment,
  image,
  content,
  primaryAction,
  primaryActionLabel,
  primaryActionStyle,
  secondaryAction,
  secondaryActionLabel,
  secondaryActionStyle,
  allPages,
}) => {
  const { fluid, description } = image
  return (
    <Block imageAlignment={imageAlignment}>
      <GatsbyImage fluid={fluid} alt={description} imageAlignment={imageAlignment} />
      <Content>
        <RichText data={content} />
        {primaryAction && (
          <LinkCta
            link={primaryAction}
            customTitle={primaryActionLabel || primaryAction.title}
            ctaStyle={primaryActionStyle || 'button'}
            alignment="left"
            allPages={allPages}
            css={{ 'margin-top': '18px' }}
          />
        )}
        {secondaryAction && (
          <LinkCta
            link={secondaryAction}
            customTitle={secondaryActionLabel || secondaryAction.title}
            ctaStyle={secondaryActionStyle || 'link'}
            alignment="left"
            allPages={allPages}
            css={{ 'margin-top': '18px' }}
          />
        )}
        {/* <RichText>{richTextRenderer(richText.json, nodeLocale)}</RichText> */}
      </Content>
    </Block>
  )
}

const Block = styled.article`
  background-color: white;
  margin: 0px 2.5%;
  padding: 40px 5% 0;
  ul {
    margin: 0px;
    padding: 0px;
  }

  ${above.mobile`
    min-height: auto;
  `}

  ${above.tablet`
    max-width: 100%;
    margin: 0px 6%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${p => (p.imageAlignment === 'row-reverse' ? 'row' : 'row-reverse')}
  `}

  ${above.tabletLarge`
    margin: 0px 9%;
    flex-wrap: nowrap;
  `}

  ${above.desktop`
    padding: 40px 118px 0;
  `}

  ${above.desktopLarge`
    max-width: 1274px;
    margin: 0px 4%;
  `}
`

const GatsbyImage = styled(Img)`
  width: auto;
  margin-bottom: 21px;
  img {
    margin-bottom: 0;
  }

  ${above.tablet`
    width: 100%;
    min-height: 200px;
  `}

  ${above.tabletLarge`
    width: 70%;
    height: 100%;
    margin: 0rem 2rem 1rem 56px;
    margin-left: ${p => (p.imageAlignment === `row-reverse` ? '0' : '2rem')};
    margin-right: ${p => (p.imageAlignment !== `row-reverse` ? '0' : '56px')}; 
  `}

    ${above.desktop`
    width: 50%;
    height: auto;
    max-height: 220px;
  `}
`

const Content = styled.div`
  width: 100%;
  color: ${color.darkGray};
  list-style: none;
  justify-content: left;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  ${above.tablet`
    width: 100%;
  `}

  h1, h2, h3, h4, h5, h6 {
    color: ${color.costaRed};
    margin: 0 0 10px 0;
    width: 100%;
  }

  p {
    margin: 0;
    color: ${color.darkGray};
    width: 100%;

    ${above.desktop`
    padding: 0%;
    `};
  }
`

// const RichText = styled.div`
//   display: flex;
//   align-items: left;
//   justify-content: left;
//   flex-direction: column;
//   margin: 0 auto;
//   padding: 0%;
//   text-align: left;
// `

Teaser.defaultProps = {
  imageAlignment: '',
  content: {},
  image: {},
  // linkCTA: [],
}

Teaser.propTypes = {
  imageAlignment: PropTypes.string,
  content: PropTypes.object,
  image: PropTypes.object,
  // linkCTA: PropTypes.array,
}

export default Teaser
