import React from 'react'
import styled from 'styled-components'

import { above } from '../../../styles'

const Icon = ({ src }) => <StyledIcon src={src.file.url} alt="icon" />

const StyledIcon = styled.img`
  width: auto;
  max-height: 52px;
  margin: 0 0 20px;

  ${above.tablet`
    max-height: 80px;
    margin: 0 0 30px;
  `}
`

export default Icon
