import React from 'react'
import styled from 'styled-components'
import { above } from '../../../styles'

export default ({ open }) => {
  return (
    <Hamburger open={open}>
      <div className="line top" />
      <div className="line middle" />
      <div className="line bottom" />
    </Hamburger>
  )
}

const Hamburger = styled.div`
  height: 32px;
  width: 92px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  cursor: pointer;
  padding: 4px;
  transition: all 0.3s;

  .line {
    height: 3px;
    width: 22px;
    background: white;
    transition: all 0.2s ease;
  }

  .top {
    transform: ${p => (p.open ? 'rotate(135deg)' : 'none')};
    /* transform-origin: top left; */
    margin-bottom: ${p => (p.open ? '-3px' : '3px')};
  }

  .middle {
    opacity: ${p => (p.open ? 0 : 1)};
    transform: ${p => (p.open ? 'translateX(-16px)' : 'none')};
  }

  .bottom {
    transform: ${p => (p.open ? 'rotate(-135deg)' : 'none')};
    /* transform-origin: top left; */
    margin-top: ${p => (p.open ? '-3px' : '3px')};
  }

  ${above.tabletLarge`
    display: none;
  `}
`
