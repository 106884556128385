export const getMicrocopy = ({ key, tokens = {}, fallback = null, data = {} }) => {
  if (!data[key]) {
    return key
  }

  const value = data[key]

  const replacements = {}
  Object.keys(tokens).forEach(token => {
    replacements[`{${token}}`] = tokens[token]
  })

  if (value && Object.keys(replacements).length) {
    const re = new RegExp(Object.keys(replacements).join('|'), 'gi')
    const result = value.replace(re, matched => {
      return replacements[matched]
    })

    return result
  }

  if (value) {
    return value
  }

  return fallback
}
